import omit from 'lodash/omit';
import isNumber from 'lodash/isNumber';

import { outcomeRepo } from './outcome.repo';
import {
  NewTemplateAttributes,
  OutcomeTemplate,
  MarginChangeEvent,
  GoesThrough,
} from './outcome.types';

import { oddToInt } from '@/lib/oddToInt';
class OutcomeService {
  getTemplates() {
    return outcomeRepo.getTemplates();
  }

  fillIntervals(totalIntervals: number) {
    return new Array(totalIntervals).fill(0);
  }

  newOutcomeTemplate({
    type,
    sport,
    sportOutcomeTypes,
    totalIntervals,
    is_default,
  }: NewTemplateAttributes): OutcomeTemplate {
    const outcomesNames = sportOutcomeTypes.map(type => type.name);

    const margin = outcomesNames.reduce(
      (o, key) => ({ ...o, [key]: this.fillIntervals(totalIntervals) }),
      {}
    );

    return {
      id: -1,
      name: 'New Template',
      template_type: type,
      sport,
      is_default,
      submarkets: {},
      margin,
    };
  }

  prepareForApi(template: OutcomeTemplate) {
    return omit(template, ['id']);
  }

  getValues(sliderValues: number[], data: MarginChangeEvent) {
    if (!isNumber(data.value)) return sliderValues;

    return sliderValues.map((v: number, index: number) => {
      return data.index <= index ? data.value : v;
    });
  }

  saveTemplate(template: OutcomeTemplate) {
    const isNew = template.id === -1;
    const payload = this.prepareForApi(template);
    const { createTemplate, updateTemplate } = outcomeRepo;
    return isNew ? createTemplate(payload) : updateTemplate({ id: template.id, payload });
  }

  deleteTemplate(id: number) {
    return outcomeRepo.deleteTemplate(id);
  }

  getMarketOutcomes(marketId: number) {
    return outcomeRepo.getMarketOutcomes(marketId);
  }

  getSubmarketsForSelectedType(markets: any[], type: number | string) {
    const submarkets = markets.reduce((submarketIds: number[], market) => {
      const ids = market.submarkets
        .filter((submarket: any) => submarket.name === type)
        .map((submarket: any) => submarket.id);
      return [...submarketIds, ...ids];
    }, []);

    return submarkets;
  }

  getGoesThrough() {
    return outcomeRepo.getGoesThrough();
  }

  mapGoesThrough(response: GoesThrough[]) {
    if (!response) return {};

    const name = response[0].market_name;
    const id = response[0].market_id;

    return {
      [id]: {
        name,
        odds: response.reduce((acc, current) => {
          // @ts-ignore
          acc[current.id] = {
            int_key: current.id,
            custom: true,
            odd_value: 0,
            outcome_name: current.name,
          };

          return acc;
        }, {}),
      },
    };
  }

  prepareOddsRequest(tempOddValues: Record<number, number>) {
    return Object.keys(tempOddValues).reduce((acc, key: any) => {
      // @ts-ignore
      acc[key] = {
        prod_odd: oddToInt(tempOddValues[key]),
      };

      return acc;
    }, {});
  }

  getLastUpdatedTime() {
    return outcomeRepo.getLastUpdatedTime();
  }
}

export const outcomeService = new OutcomeService();
