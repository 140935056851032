import to from 'await-to-js';
import isArray from 'lodash/isArray';
import uniq from 'lodash/uniq';
import orderBy from 'lodash/orderBy';

import { marketRepo } from './market.repo';
import {
  IMarket,
  MarketTemplateCheckedState,
  Odd,
  OddsMap,
  Outcome,
  SubMarket,
  TemplateItem,
  TemplateMarket,
  PlayerMarketTemplate,
  OddType,
} from './market.types';

import { oddToInt } from '@/lib/oddToInt';
import { FeedLiveOdd, FeedOdd } from '../feed';
import sortBy from 'lodash/sortBy';

class MarketService {
  async getMarkets(eventId: number) {
    const res = await marketRepo.getMarkets(eventId);
    return res.sort((a, b) => a.id - b.id);
  }

  getLiveMarkets(eventIntKey: number) {
    return marketRepo.getLiveMarkets(eventIntKey);
  }

  async getSportMarkets(sportId: number) {
    const [err, res] = await to(marketRepo.getSportMarkets(sportId));
    if (err) return err;
    return this.parseMarketsResponse(res);
  }

  async getSportMarketGroups(sportId: number) {
    const [err, res] = await to(marketRepo.getSportMarketGroups(sportId));
    if (err) return err;
    return res;
  }

  async getPlayerMarkets(sportId: number) {
    const [err, res] = await to(marketRepo.getPlayerMarkets({ sport_id: sportId }));
    if (err) return err;
    return res;
  }

  async getPlayerEventMarkets(intKey: number) {
    const [err, res] = await to(marketRepo.getPlayerEventMarkets(intKey));
    if (err) return err;
    return res;
  }

  getSportSubmarkets(sportId: number) {
    return marketRepo.getSportSubMarkets(sportId);
  }

  parseMarketsResponse(data: any) {
    return data.map((market: any) => {
      return {
        ...market,
        submarkets: market.submarkets.map((item: any) => ({
          ...item,
          label: this.getOutcomeGroupName(item.outcomes),
        })),
      };
    });
  }

  createPlayerOddsMap(odds: Odd[]): OddsMap {
    return odds.reduce((acc, odd) => {
      return {
        ...acc,
        [odd.int_key]: {
          ...odd,
          // @ts-ignore
          prod_odd: odd.prod_odd / 100,
        },
      };
    }, {});
  }

  createOddsMap(odds: Odd[], oddType: string): OddsMap {
    return odds.reduce((acc, odd) => {
      // @ts-ignore
      return { ...acc, [odd.int_key]: { ...odd, [oddType]: odd[oddType] / 100 } };
    }, {});
  }

  getOddsMap(markets: IMarket[], oddType: string): OddsMap {
    if (!markets.length) return {};
    if (oddType === OddType.player) {
      return markets.reduce((acc, market) => {
        const odds = this.createPlayerOddsMap(market.odds);
        return { ...acc, ...odds };
      }, {});
    }
    return markets.reduce((acc, market) => {
      const odds = this.createOddsMap(market.odds, oddType);
      return { ...acc, ...odds };
    }, {});
  }

  getTemplates() {
    return marketRepo.getTemplates();
  }

  deleteTemplate(templateId: number) {
    return marketRepo.deleteTemplate(templateId);
  }

  getOutcomeGroupName(outcomes: Outcome[]) {
    if (!outcomes) return '';
    const sortedOutcomes = sortBy(outcomes, outcome => outcome.id);
    return sortedOutcomes.map(o => o.name).join(', ');
  }

  getMarketSubmarkets(selectedMarket: number, markets: TemplateMarket[]) {
    if (!markets) return [];
    const market = markets.find((m: any) => m.id === selectedMarket);
    if (!market) return [];
    return (
      (market.submarkets && orderBy(market.submarkets, 'id')) ||
      (market.outcome_players && orderBy(market.outcome_players, 'id'))
    );
  }

  getMarketSubmarketsByType(
    selectedMarket: number,
    markets: TemplateMarket[],
    outcomeType: string
  ) {
    if (!markets) return [];

    const market = markets.find((m: any) => m.id === selectedMarket);
    if (!market) return [];
    return market.submarkets.filter(s => s.name === outcomeType) || market.outcome_players;
  }

  mapOutcomesOnlyId(outcomes: Outcome[]) {
    return outcomes.map(o => o.id);
  }

  getOutcomesIds(submarkets?: SubMarket[]): number[] {
    if (!submarkets) return [];
    return submarkets.reduce((agg: any, market) => {
      const outcomes = market.outcomes || market;
      if (!isArray(outcomes)) return [...agg, (outcomes as any).id];
      return [...agg, ...this.mapOutcomesOnlyId(outcomes)];
    }, []);
  }

  mapOutcomes(outcomes: Outcome[]) {
    return outcomes.map(o => {
      return {
        name: o.name,
        id: o.id,
      };
    });
  }

  getOutcomesNames(submarkets?: SubMarket[]): number[] {
    if (!submarkets) return [];
    return submarkets.reduce((agg: any, market) => {
      const outcomes = market.outcomes || market;
      if (!isArray(outcomes)) return [...agg, (outcomes as any).name];
      return [...agg, ...this.mapOutcomes(outcomes)];
    }, []);
  }

  buildForApi(
    {
      id,
      name,
      sport_id,
      template,
      template_type,
      is_default,
    }: {
      id: number;
      name: string;
      sport_id: number;
      template: TemplateItem;
      template_type: 'LIVE' | 'PREMATCH';
      is_default: boolean;
    },
    isNew: boolean
  ) {
    const data: any = {
      name,
      sport_id,
      template,
      template_type,
      is_default,
    };

    if (isNew === false) {
      data.id = id;
    }
    return data;
  }

  saveTemplate(data: any) {
    const isNew = data.id === -1;
    const api = isNew ? marketRepo.createTemplate : marketRepo.updateTemplate;
    const payload = this.buildForApi(data, isNew);
    return api(payload);
  }

  addOutcomeToMarket(marketId: number, outcome: number | number[], template: any[]) {
    const marketOutcomes = template.find((t: any) => t.market_id === marketId);
    const toBeAdded = Array.isArray(outcome) ? outcome : [outcome];
    if (!marketOutcomes) {
      return [
        ...template,
        {
          market_id: marketId,
          outcomes: toBeAdded,
        },
      ];
    } else {
      return template.reduce((acc, config) => {
        if (config.market_id === marketId) {
          return [
            ...acc,
            {
              ...config,
              outcomes: uniq([...config.outcomes, ...toBeAdded]),
            },
          ];
        }

        return [...acc, config];
      }, []);
    }
  }

  getAllSubmarkets(markets: TemplateMarket[]) {
    return markets.reduce((acc: SubMarket[], market) => {
      return [...acc, ...market.submarkets];
    }, []);
  }

  removeOutcomeFromMarket(marketId: number, outcome: number | number[], template: any[]) {
    const toBeRemoved = Array.isArray(outcome) ? outcome : [outcome];
    return template.reduce((acc, config) => {
      if (config.market_id === marketId) {
        return [
          ...acc,
          {
            ...config,
            outcomes: config.outcomes.filter((o: any) => !toBeRemoved.includes(o)),
          },
        ];
      }

      return [...acc, config];
    }, []);
  }

  calculateSubMarketCheckState = (
    subMarket: any,
    template: any,
    marketId?: number
  ): MarketTemplateCheckedState => {
    if (!marketId || !template) return 'uncheked';
    const config = template.template.find(({ market_id }: any) => market_id === marketId);
    if (!config) return 'uncheked';
    const subMarketOutcomes = this.getOutcomesIds([subMarket]);
    const res = subMarketOutcomes.every((v: any) => config.outcomes.includes(v));
    return res ? 'checked' : 'uncheked';
  };

  calculateMarketCheckState(market: any, template: any): MarketTemplateCheckedState {
    if (!market || !template) return 'uncheked';
    const subMarkets = market.submarkets || market.outcome_players;
    const submarketsState = subMarkets.map((subMarket: any) =>
      this.calculateSubMarketCheckState(subMarket, template, market.id)
    );
    const isAllCheked = submarketsState.every(
      (state: MarketTemplateCheckedState) => state === 'checked'
    );

    const isAllUnCheked = submarketsState.every(
      (state: MarketTemplateCheckedState) => state === 'uncheked'
    );

    if (isAllCheked) return 'checked';
    if (isAllUnCheked) return 'uncheked';

    return 'intermediate';
  }

  getMarketsByType(markets: TemplateMarket[], type: string) {
    const reducer = (acc: TemplateMarket[], val: TemplateMarket) => {
      let isMatch = false;
      val.submarkets?.forEach(sm => {
        if (sm.name === type) isMatch = true;
      });
      return isMatch ? [...acc, val] : acc;
    };

    return markets.reduce(reducer, []);
  }

  // from markets endpoint we exclude odds object because it has all odds connected to that market
  buildMarketsWithoutOdds(markets: PlayerMarketTemplate[]) {
    if (!markets) return [];
    return markets.map((market: any) => ({
      id: market.id,
      is_base: market.is_base,
      // check this, this is stil undefined
      prematch_max_amount: market.prematch_max_amount,
      name: market.name,
      outcome_players: market.outcome_players.sort((a: any, b: any) => a.id - b.id),
    }));
  }

  // find event template
  getEventTemplateMarkets(sportTemplates: any, marketTemplateId: number | null | undefined) {
    if (!marketTemplateId) return [];
    return sportTemplates.find((t: any) => t.id === marketTemplateId)?.template;
  }

  // get only markets included in market template
  getEventMarketsFromTemplate(
    playerMarkets: PlayerMarketTemplate[],
    eventMarketTemplateMarkets: TemplateItem[]
  ) {
    return eventMarketTemplateMarkets.reduce((marketsFromTemplate: any, marketTemplate: any) => {
      const matchingMarket = playerMarkets.find((pm: any) => pm.id === marketTemplate.market_id);
      if (matchingMarket) {
        matchingMarket.outcome_players = matchingMarket.outcome_players.filter((op: any) =>
          marketTemplate.outcomes.includes(op.id)
        );
        marketsFromTemplate.push(matchingMarket);
        return marketsFromTemplate;
      }
    }, []);
  }

  getPlayerOutcomesConfig(eventMarketConfig: any[], markets: IMarket[]) {
    return markets.reduce((outcomesConfig: any[], market: IMarket) => {
      const config = outcomesConfig.find((vm: any) => vm.id === market.id);
      if (config) {
        config.odds = market.odds || [];
        config.is_suspended = market.is_suspended;
        config.prematch_max_amount = market.prematch_max_amount;
      }

      return outcomesConfig;
    }, eventMarketConfig);
  }

  sortOddsByOutcomeId(odds: Odd[]) {
    return odds.sort((a, b) => a.outcome_id - b.outcome_id);
  }

  sortOddsByLimitOrOutcomeId(odds: any[]) {
    if (odds.length && !odds[0].limit) return odds.sort((a, b) => a.outcome_id - b.outcome_id);
    const mapLimits = odds.reduce((groupOdds, odd) => {
      const { limit } = odd;
      if (limit) {
        groupOdds[limit] = groupOdds[limit] ?? [];
        groupOdds[limit].push(odd);
      } else {
        const outcome_name = odd.outcome_name.split('-')[0];
        groupOdds[outcome_name] = groupOdds[outcome_name] ?? [];
        groupOdds[outcome_name].push(odd);
      }
      return groupOdds;
    }, {});
    const limits = [];
    for (const key in mapLimits) {
      if (Object.keys(mapLimits).length && Object.keys(mapLimits).length == 1) {
        limits.push(...mapLimits[key].sort((a: any, b: any) => a.outcome_id - b.outcome_id));
      } else {
        limits.push(...mapLimits[key]);
      }
    }
    return orderBy(limits, 'limit', 'asc');
  }

  marketHasLimits(market: IMarket) {
    if (!market.odds[0].limit) return false;
    return market.odds[0].limit !== 0;
  }

  formatGoesThroughOdds(odds: any) {
    const keys = Object.keys(odds);
    return keys.reduce((acc, key) => {
      return {
        ...acc,
        [key]: oddToInt(odds[key]),
      };
    }, {});
  }

  getSubmarketsLabel = (m: SubMarket) =>
    `${m.label || m.name} ${m?.outcomes?.[0].alt_name ? `- ${m.outcomes[0].alt_name}` : ''}`;

  mapLiveOdd = (odd: FeedLiveOdd) => {
    return {
      outcome_id: odd.o,
      live_real_odd: odd.r_v,
      live_prod_odd: odd.p_v,
      live_max_amount_web: null,
      live_max_amount_sc: null,
      manual_changed: false,
      limit: odd.lim,
      int_key: odd.i_k,
      is_suspended: odd.i_s,
      isdbp: odd.isdbp,
      market_id: odd.m,
      outcome_name: odd.on,
      market_name: odd.mn,
    };
  };

  mapOdd = (odd: FeedOdd) => {
    return {
      outcome_id: odd.o,
      prematch_real_odd: odd.r_v,
      prematch_prod_odd: odd.p_v,
      live_max_amount_web: null,
      live_max_amount_sc: null,
      manual_changed: odd.m_c,
      limit: odd.l,
      int_key: odd.i_k,
      id: odd.i_k,
      is_suspended: odd.i_s,
      isdbp: odd.isdbp,
      market_id: odd.m,
      outcome_name: odd.on,
      market_name: odd.mn,
    };
  };
}

export const marketService = new MarketService();
