import { ApiListResponse } from '@/types/api';
import { TemplateType } from '../outcome/outcome.types';

export interface Odd {
  id: string;
  int_key: number;
  limit: null | number;
  limit2: null | number;
  outcome_id: number;
  outcome_name: string;
  is_suspended: boolean;
  live_prod_odd?: number;
  live_real_odd?: string;
  prematch_prod_odd?: number | string | undefined;
  prematch_real_odd?: string;
  prod_odd?: number | undefined;
  live_max_amount_sc: null | number;
  live_max_amount_web: null | number;
  prematch_max_amount: null | number;
  manual_changed: boolean;
  isdbp: boolean;
}

export interface IMarket {
  id: number;
  name: string;
  prematch_max_amount: null | number;
  live_max_amount_web: null | number;
  live_max_amount_sc: null | number;
  is_suspended: boolean;
  is_player: boolean;
  odds: Odd[];
}

export interface TemplateMarket {
  outcome_players: any;
  id: number;
  name: string;
  submarkets: SubMarket[];
  is_live: boolean;
}

export interface DifferenceTemplateMarket extends TemplateMarket {
  types: string;
}

export interface PlayerMarketTemplate {
  id: number;
  is_base: boolean;
  name: string;
  outcome_players: Outcome[];
}

export interface MarketTemplate {
  is_default: boolean;
  id: number;
  name: string;
  sport_id: number;
  template_type: TemplateType;
  template: any;
}

export type MarketWithoutOdds = Omit<IMarket, 'odds'>;

export interface OddsMap {
  [key: number]: Odd;
}

export interface SportMarketMap {
  [key: number]: TemplateMarket[];
}

export interface SubMarket {
  id: number;
  name: string;
  outcomes: Outcome[];
  label: string;
  alt_name: string;
}

interface SportSubMarket {
  name: string;
  is_live: boolean;
}

export interface SportSubMarketMap {
  [key: number]: SportSubMarket[];
}

export interface Outcome {
  id: number;
  name: string;
  alt_name: string;
}

export interface TemplateItem {
  market_id: number;
  outcomes: number[];
}

export interface PlayerMarket {
  id: number;
  name: string;
  outcome_players: Outcome[];
}

export type PlayerMarketListResponse = ApiListResponse<PlayerMarket>;

export type MarketListResponse = ApiListResponse<IMarket>;
export type MarketTemplateCheckedState = 'checked' | 'uncheked' | 'intermediate';

interface MarketEventSuspended {
  e_i_k: string;
  m_i: number;
}

export type MarketEventSuspendedMessage = MarketEventSuspended[];

export enum OddType {
  prematch = 'prematch_prod_odd',
  live = 'live_prod_odd',
  player = 'player_prod_odd',
}

export interface GoesThroughMarket {
  [key: string]: {
    odds: Odd[];
    name: string;
  };
}

export interface OutcomePlayerOdd {
  has_limit: boolean;
  id: number;
  name: string;
  type_code: string;
}
