import { api } from '@/lib/api';
import { PlayerMarketListResponse } from '.';
import { IMarket, MarketListResponse, MarketWithoutOdds } from './market.types';

const getMarkets = (eventId: number, params?: any) => {
  return api.get(`odds/events/${eventId}/markets/`, { params, cache: { maxAge: 0 } }) as Promise<
    IMarket[]
  >;
};

const getLiveMarkets = (eventIntKey: number, params?: any) => {
  return api.get(`odds/events/${eventIntKey}/live-markets/`, {
    params,
    cache: { maxAge: 0 },
  }) as Promise<MarketListResponse>;
};

const getPlayerMarkets = (payload: any): Promise<PlayerMarketListResponse> => {
  return api.get(`odds/players/markets/`, {
    params: payload,
    cache: {
      exclude: {
        query: false,
      },
    },
  }) as Promise<PlayerMarketListResponse>;
};

const getPlayerEventMarkets = (intKey: number) => {
  return api.get(`odds/event-players/${intKey}/markets/`, { cache: { maxAge: 0 } });
};

const getSportMarkets = (sportId: number) => {
  return api.get(`/odds/sports/${sportId}/markets/`) as Promise<MarketWithoutOdds[]>;
};

const getSportMarketGroups = (sportId: number) => {
  return api.get(`/odds/sports/${sportId}/market-groups/`);
};

const getSportSubMarkets = (sportId: number) => {
  return api.get(`/odds/sports/${sportId}/submarkets/`);
};

const getTemplates = () => {
  return api.get(`/odds/template/market-templates/`, {
    cache: { maxAge: 0 },
  });
};

const deleteTemplate = (templateId: number) => {
  return api.delete(`/odds/template/market-templates/${templateId}/`);
};

const createTemplate = (data: any) => {
  return api.post('/odds/template/market-templates/', data);
};

const updateTemplate = (data: any) => {
  return api.put(`/odds/template/market-templates/${data.id}/`, data);
};

export const marketRepo = {
  createTemplate,
  deleteTemplate,
  getMarkets,
  getSportMarkets,
  getSportSubMarkets,
  getTemplates,
  getSportMarketGroups,
  updateTemplate,
  getLiveMarkets,
  getPlayerMarkets,
  getPlayerEventMarkets,
};
