import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import Vue from 'vue';
import to from 'await-to-js';
import uniqBy from 'lodash/uniqBy';

import store from '@/store';
import {
  IMarket,
  MarketWithoutOdds,
  OddsMap,
  SportMarketMap,
  SportSubMarketMap,
  Odd,
  MarketTemplate,
  MarketEventSuspendedMessage,
  OddType,
} from './market.types';
import { DEFAULT_LIMIT } from '@/constants';
import { HAS_GOES_TROUGH } from '@/modules/sport/sport.contants';
import { marketService } from './market.service';
import { GoesThroughResponse, TemplateType } from '../outcome/outcome.types';
import { objToArr } from '@/lib/objToArr';
import { outcomeService } from '../outcome/outcome.service';

const initialState = () => ({
  markets: null,
  sportMarkets: {},
  sportMarketGroups: {},
  sportSubMarkets: {},
  templates: [],
  odds: null,
  isLoading: false,
  playerMarkets: {},
  tempOddValues: {},
  params: {
    limit: DEFAULT_LIMIT,
  },
  activeId: 0,
});

interface TogleSubMarkets {
  value: boolean;
  marketId: number;
  activeTemplate: number;
  sportId: number;
  isLive: boolean;
  isPlayer: boolean;
}

@Module({ dynamic: true, store, name: 'market', namespaced: true })
class Market extends VuexModule {
  markets: null | IMarket[] = initialState().markets;
  sportMarkets: SportMarketMap = initialState().sportMarkets;
  sportMarketGroups: any = initialState().sportMarketGroups;
  odds: null | OddsMap = initialState().odds;
  isLoading = initialState().isLoading;
  params = initialState().params;
  templates: MarketTemplate[] = initialState().templates;
  sportSubMarkets: SportSubMarketMap = initialState().sportMarkets;
  playerMarkets: any = initialState().playerMarkets;
  activeId: number | string = initialState().activeId;
  goesThrough: any = [];
  tempOddValues: any = {};
  sport = '';

  get hasMarkets() {
    if (!this.markets) return false;
    return this.markets.length > 0 && this.isLoading === false;
  }

  get singleSportMarkets() {
    return (sportId: number, isLive: boolean) => {
      const markets = this.sportMarkets[sportId] || [];
      return markets.filter(m => m.is_live === isLive);
    };
  }

  get singleSportPlayerMarkets() {
    return (sportId: number) => {
      return this.playerMarkets[sportId];
    };
  }

  get singleSportMarketsByType() {
    return (sportId: number, isLive: boolean, type: string) =>
      marketService.getMarketsByType(this.singleSportMarkets(sportId, isLive), type);
  }

  get singleSportSubMarkets() {
    return (sportId: number, isLive: boolean) => {
      const subMarkets = this.sportSubMarkets[sportId] || [];
      // dirty hack to remove duplicates
      // for some reason backend can't return uniqe values
      return uniqBy(
        subMarkets.filter(subMarket => subMarket.is_live === isLive),
        'name'
      );
    };
  }

  get goesThroughMarket() {
    if (!this.goesThrough) return {};
    return outcomeService.mapGoesThrough(this.goesThrough[this.sport]['prematch']);
  }

  get hasMapGoesThrough() {
    if (!this.goesThrough[this.sport]) return false;
    const market = this.markets?.find(market => {
      return market.id === this.goesThrough[this.sport]['prematch'][0]?.market_id;
    });
    const hasMarket = !!market;

    return hasMarket && market?.name && market.odds.length;
  }

  get singleSportMarketGroups() {
    return (sportId: number) => this.sportMarketGroups[sportId] || [];
  }

  get sportTemplates() {
    return (sportId: number, templateType: string) => {
      return this.templates.filter(t => t.sport_id === sportId && t.template_type === templateType);
    };
  }

  get getActiveTemplate() {
    return (templateId: number) =>
      this.templates.find((template: any) => template.id === templateId);
  }

  get allOddsKeys() {
    return Object.keys(this.odds || {});
  }

  get isGoesThroughEnabled() {
    return (templateId: any, sportId: number) => {
      if (!templateId) return false;
      if (!HAS_GOES_TROUGH[sportId]) return false;

      const sport = HAS_GOES_TROUGH[sportId];
      const marketId = this.goesThrough[sport]['prematch'][0].market_id;
      const template = this.templates.find(t => t.id === templateId);
      if (!template) return false;

      const marketIds = template.template.map((t: any) => t.market_id);
      return marketIds.includes(marketId);
    };
  }

  @Mutation
  setMarkets(markets: IMarket[]) {
    const data = markets.map(market => {
      return {
        ...market,
        odds: marketService.sortOddsByLimitOrOutcomeId(market.odds),
      };
    });

    this.markets = data;
  }

  @Mutation
  resetMarkets() {
    this.markets = initialState().markets;
  }

  @Mutation
  setGoesThroughOutcomes(data: GoesThroughResponse) {
    this.goesThrough = data;
  }

  @Mutation
  setTempOddValue({ id, value }: any) {
    Vue.set(this.tempOddValues, id, value);
  }

  @Mutation
  clearTempValues() {
    this.tempOddValues = {};
  }

  @Mutation
  setSportMarket({ sportId, markets }: { sportId: number; markets: MarketWithoutOdds[] }) {
    Vue.set(this.sportMarkets, sportId, markets);
  }

  @Mutation
  setSportMarketGroups({ sportId, markets }: { sportId: number; markets: any }) {
    Vue.set(this.sportMarketGroups, sportId, markets);
  }

  @Mutation
  setSportSubmarkets({ sportId, subMarkets }: { sportId: number; subMarkets: any }) {
    Vue.set(this.sportSubMarkets, sportId, subMarkets);
  }

  @Mutation
  setPlayerMarkets({ sportId, markets }: { sportId: number; markets: any }) {
    Vue.set(this.playerMarkets, sportId, markets);
  }

  @Mutation
  removeUnsavedTemplates() {
    this.templates = this.templates.filter((t: any) => t.id !== -1);
  }

  @Mutation
  updateMarket({ id, params }: { id: any; params: Partial<IMarket> }) {
    this.markets?.forEach((market, index) => {
      if (market.id === id) {
        const updatedMarket = {
          ...market,
          ...params,
        };
        // @ts-ignore
        Vue.set(this.markets, index, updatedMarket);
      }
    });
  }

  @Mutation
  updateMarketOdds({ odd, eventId }: { odd: any; eventId: number }) {
    if (!this.markets) return;
    const market = this.markets.find((market: any) => market.id === odd.market_id);

    if (market) {
      const oddToUpdate = market.odds.find((marketOdd: Odd) => marketOdd.int_key === odd.int_key);
      if (!oddToUpdate && eventId === this.activeId) {
        market.odds.push(odd);
      }
      if (oddToUpdate && this.activeId === eventId) {
        oddToUpdate.isdbp = odd.isdbp;
      }
      const index = market.odds.findIndex(odd => odd.isdbp);
      if (index === -1) return;

      setTimeout(() => {
        market.odds.splice(index, 1);
      }, 2000);
    }
  }

  @Mutation
  setOdds(odds: OddsMap) {
    this.odds = odds;
  }

  @Mutation
  private setLoading(nextValue: boolean) {
    this.isLoading = nextValue;
  }

  @Mutation
  updateOdd({ id, params }: { id: number; params: Partial<Odd> }) {
    if (!this.odds) return;
    const odd = this.odds[id];
    if (!odd) return;
    Vue.set(this.odds, id, { ...odd, ...params });
  }

  @Mutation
  resetStore(ignore: string[] = []) {
    const initial = initialState() as any;
    Object.keys(initial).forEach(key => {
      if (!ignore.includes(key)) {
        this[key as keyof this] = initial[key];
      }
    });
  }

  @Mutation
  setTemplates(results: any) {
    this.templates = results;
  }

  @Mutation
  private removeTemplateFromStore(templateId: number) {
    const index = this.templates.map((t: any) => t.id).indexOf(templateId);
    this.templates.splice(index, 1);
  }

  @Mutation
  updateTemplate({ id, attributes }: any) {
    this.templates.forEach((template: any, index: number) => {
      if (template.id === id) {
        Vue.set(this.templates, index, { ...template, ...attributes });
      }
    });
  }

  @Mutation
  newTemplate({ sportId, template_type }: { sportId: number; template_type: TemplateType }) {
    this.templates.push({
      id: -1,
      name: 'New Template',
      sport_id: sportId,
      template_type,
      is_default: false,
      template: [],
    });
  }

  @Mutation
  copyToNewTemplate(id: number) {
    const template = this.templates.find(template => template.id === id);
    if (template)
      this.templates.push({
        ...template,
        id: -1,
        name: `${template.name} Copy`,
        is_default: false,
      });
  }

  @Mutation
  removeDefaultTemplate(id: number | null) {
    this.templates.forEach((template: any, index: number) => {
      if (template.id !== id) {
        Vue.set(this.templates, index, { ...template, is_default: false });
      }
    });
  }

  @Mutation
  setGoesThroughData({ sportId }: { sportId: number }) {
    this.sport = HAS_GOES_TROUGH[sportId];
  }

  @Mutation
  setActiveEvent(eventId: number | string) {
    this.activeId = eventId;
  }

  @Action
  async getMarkets(eventIntKey: number) {
    this.setLoading(true);
    const [_err, response] = await to(marketService.getMarkets(eventIntKey));
    this.setLoading(false);
    if (response) {
      this.setMarkets(response as any);
      const odds = marketService.getOddsMap(response as any, OddType.prematch);
      this.setOdds(odds);
    }
  }

  @Action
  async getLiveMarkets(eventIntKey: number) {
    this.setLoading(true);
    const [_err, response] = await to(marketService.getLiveMarkets(eventIntKey));
    this.setLoading(false);
    if (response) {
      const marketsArray = Object.values(response);
      this.setMarkets(marketsArray as any);
      const odds = marketService.getOddsMap(marketsArray as any, OddType.live);
      this.setOdds(odds);
    }
  }

  @Action
  async getSportMarkets(sportId: number) {
    const [err, response] = await to(marketService.getSportMarkets(sportId));
    if (err) {
      console.error(err);
    }
    if (!response) return;
    this.setSportMarket({
      sportId,
      markets: response,
    });
  }

  @Action
  async getSportMarketGroups(sportId: number) {
    const [err, response] = await to(marketService.getSportMarketGroups(sportId));
    if (err) {
      console.error(err);
    }
    if (!response) return;
    this.setSportMarketGroups({
      sportId,
      markets: response,
    });
  }

  @Action
  async getSportSubMarkets(sportId: number) {
    const [err, response] = await to(marketService.getSportSubmarkets(sportId));
    if (err) {
      console.error(err);
    }
    this.setSportSubmarkets({
      sportId,
      subMarkets: response,
    });
  }

  @Action
  async getPlayerMarkets(sportId: number) {
    const [err, res] = await to(marketService.getPlayerMarkets(sportId));
    if (err) return console.error(err);

    this.setPlayerMarkets({ sportId, markets: objToArr(res as object) });
  }

  @Action
  async getPlayerEventMarkets(intKey: number) {
    const [err, res] = await to(marketService.getPlayerEventMarkets(intKey));
    if (err) return console.error(err);
    if (res) {
      this.setMarkets(res as any);
      const odds = marketService.getOddsMap(res as any, OddType.player);
      this.setOdds(odds);
    }
  }

  @Action
  async getTemplates() {
    const [err, response] = await to(marketService.getTemplates());
    if (err) return Promise.reject(err);
    this.setTemplates(response);
  }

  @Action
  toggleSubMarkets({
    value,
    marketId,
    activeTemplate,
    sportId,
    isLive,
    isPlayer,
  }: TogleSubMarkets) {
    const { template } = this.getActiveTemplate(activeTemplate) as any;
    const market = isPlayer
      ? this.singleSportPlayerMarkets(sportId).find((m: any) => m.id === marketId)
      : (this.singleSportMarkets(sportId, isLive).find((m: any) => m.id === marketId) as any);
    const outcomes = marketService.getOutcomesIds(market?.submarkets || market.outcome_players);
    let result;
    if (value) {
      result = marketService.addOutcomeToMarket(marketId, outcomes, template);
    } else {
      result = marketService.removeOutcomeFromMarket(marketId, outcomes, template);
    }
    this.updateTemplate({
      id: activeTemplate,
      attributes: { template: result },
    });
  }

  @Action
  togleSubMarket({ subMarket, value, activeTemplate, marketId }: any) {
    const { template } = this.getActiveTemplate(activeTemplate) as any;
    let outcomes: any[];
    if (subMarket.outcomes) {
      // Player markets act like submarkets, if its plateyer market it wont be nested in outcomes
      outcomes = subMarket.outcomes.map((o: any) => o.id);
    } else {
      outcomes = [subMarket.id];
    }
    let result;
    if (value) {
      result = marketService.addOutcomeToMarket(marketId, outcomes, template);
    } else {
      result = marketService.removeOutcomeFromMarket(marketId, outcomes, template);
    }
    this.updateTemplate({
      id: activeTemplate,
      attributes: { template: result },
    });
  }

  @Action
  async deleteTemplate(id: number) {
    const [err] = await to(marketService.deleteTemplate(id));
    if (!err) {
      this.removeTemplateFromStore(id);
    }
  }

  @Action
  async saveTemplate(data: any) {
    const selectedTemplate = this.getActiveTemplate(data.id);
    const payload = { ...selectedTemplate, ...data };
    const isAnythingSelected = payload.template
      .map((market: any) => !!market.outcomes.length)
      .includes(true);
    if (!isAnythingSelected) return Promise.reject('error');
    const [err] = await to(marketService.saveTemplate(payload));
    if (!err) {
      setTimeout(() => this.getTemplates(), 700);
      return Promise.resolve();
    }
    return Promise.reject(err);
  }

  @Action
  async onMarketUnsuspend(message: MarketEventSuspendedMessage) {
    message.forEach(change => {
      this.updateMarket({ id: change.m_i, params: { is_suspended: false } });
    });
  }

  @Action
  async onMarketSuspend(message: MarketEventSuspendedMessage) {
    message.forEach(change => {
      this.updateMarket({ id: change.m_i, params: { is_suspended: true } });
    });
  }

  @Action
  async removeDefault(id: number | null) {
    this.removeDefaultTemplate(id);
  }

  @Action
  async getGoesThrough() {
    const [err, res] = await to(outcomeService.getGoesThrough());
    if (err || !res) return;
    this.setGoesThroughOutcomes(res);
  }
}

export const MarketStore = getModule(Market);
