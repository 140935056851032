import { api } from '@/lib/api';
import { GoesThroughResponse, OutcomTemplatePayload } from './outcome.types';

const getTemplates = () => {
  return api.get('/odds/template/outcome-templates/', { params: { limit: 1000 } });
};

const createTemplate = (data: OutcomTemplatePayload) => {
  return api.post('/odds/template/outcome-templates/', data);
};

const updateTemplate = ({ id, payload }: { id: number; payload: OutcomTemplatePayload }) => {
  return api.put(`/odds/template/outcome-templates/${id}/`, payload);
};

const deleteTemplate = (id: number) => {
  return api.delete(`/odds/template/outcome-templates/${id}/`);
};

const getMarketOutcomes = (marketId: number) => {
  return api.get(`/odds/markets/${marketId}/outcomes/`);
};

const getGoesThrough = () => {
  return api.get('/odds/outcomes/goes-through/') as Promise<GoesThroughResponse>;
};

const createOdds = (eventId: number, payload: Record<number, number>) => {
  return api.put(`/odds/events/${eventId}/odds/`, payload);
};

const getLastUpdatedTime = () => {
  return api.get('odds/template/outcome-templates/last-update-time/');
};

export const outcomeRepo = {
  getTemplates,
  getMarketOutcomes,
  createTemplate,
  updateTemplate,
  deleteTemplate,
  getGoesThrough,
  createOdds,
  getLastUpdatedTime,
};
